//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      newGroup: {
        name: '',
        loader: false,
      },
      loader: false,
      showedExtra: [],
      selected: {},
      groupUserSearch: {},
      mapOfIdLists: {},
    };
  },

  computed: {
    ...mapState('usersGroups', ['groups']),
  },

  methods: {
    ...mapActions('common', [
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
      'setGeneralProgress',
    ]),

    ...mapActions('usersGroups', ['addGroup', 'getGroups', 'removeGroup', 'removeUsersFromGroup', 'addUsersToGroup']),

    isExtra(id) {
      const { showedExtra } = this;
      return showedExtra.includes(id);
    },

    toggleExtra(id) {
      const { showedExtra } = this;
      const isShow = showedExtra.includes(id);
      if (isShow) {
        this.showedExtra = showedExtra.filter((e) => e !== id);
      } else {
        this.showedExtra.push(id);
      }
    },

    showUserChip(groupId, userId, userName) {
      if (!this.groupUserSearch[groupId] || !userId || !userName) {
        return true;
      }

      return this.groupUserSearch[groupId]?.includes(String(userId))
        || this.groupUserSearch[groupId]?.includes(userName)
        || String(userId).includes(this.groupUserSearch[groupId])
        || userName.includes(this.groupUserSearch[groupId]);
    },

    close() {
      this.$emit('input', false);
    },

    clickHandler(groupId, userId) {
      if (this.selected[groupId]?.[userId]) {
        const { [userId]: exclude, ...other } = this.selected[groupId];
        this.selected = { ...this.selected, [groupId]: other };
      } else {
        this.selected = { ...this.selected, [groupId]: { ...this.selected[groupId], [userId]: true } };
      }
    },

    async addUsersToGroupHandler(groupId, groupName) {
      this.setGeneralProgress(true);
      if (this.mapOfIdLists[groupId]) {
        const userIdList = Object.values(this.mapOfIdLists[groupId]);
        if (groupId && this.mapOfIdLists[groupId] && userIdList.length) {
          this.confirmAction({
            title: `Are you sure you want to add users with ids "${userIdList.join('", "')}" to the "${groupName}" group?`,
            callback: async () => {
              this.loader = true;
              try {
                await this.addUsersToGroup({
                  userId: userIdList,
                  id: groupId,
                });
                await this.getGroups();
                this.setSuccessNotification(`Users with ids "${userIdList.join('", "')}" successfuly added to the "${groupName}" group`);
              } catch (error) {
                this.setErrorNotification(error.message);
              }
              this.loader = false;
            },
          });
        }
      }
      this.setGeneralProgress(false);
    },

    deleteUsersFromGroup(groupId, groupName) {
      this.setGeneralProgress(true);
      if (this.selected[groupId]) {
        const userIdList = Object.keys(this.selected[groupId]);
        if (groupId && this.selected[groupId] && userIdList.length) {
          this.confirmAction({
            title: `Are you sure you want to delete users with ids "${userIdList.join('", "')}" from "${groupName}" group?`,
            callback: async () => {
              this.loader = true;
              try {
                await this.removeUsersFromGroup({
                  userId: userIdList,
                  id: groupId,
                });
                await this.getGroups();
                this.setSuccessNotification(`Users with ids "${userIdList.join('", "')}" successfuly deleted from "${groupName}" group`);
              } catch (error) {
                this.setErrorNotification(error.message);
              }
              this.loader = false;
            },
          });
        }
      }
      this.setGeneralProgress(false);
    },

    async deleteGroup(group) {
      this.confirmAction({
        title: `Are you sure you want to delete group "${group.name}"?`,
        callback: async () => {
          this.loader = true;
          try {
            await this.removeGroup(group);
            await this.getGroups();
            this.setSuccessNotification('Group successfuly deleted');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.loader = false;
        },
      });
    },

    async addNewGroup() {
      this.newGroup.loader = true;
      this.confirmAction({
        title: `Are you sure you want to create group "${this.newGroup.name}"?`,
        callback: async () => {
          this.loader = true;
          try {
            const { name } = this.newGroup;
            await this.addGroup({ Name: name });
            await this.getGroups();
            this.setSuccessNotification('Group successfuly created');
            this.newGroup.name = '';
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.loader = false;
        },
      });
      this.newGroup.loader = false;
    },

    async removeUserFromGroup({
      groupId, groupName, userId, userName,
    }) {
      this.setGeneralProgress(true);
      if (groupId && userId) {
        this.confirmAction({
          title: `Are you sure you want to delete user "${userName}" with id "${userId}" from "${groupName}" group?`,
          callback: async () => {
            this.loader = true;
            try {
              await this.removeUsersFromGroup({
                userId: [userId],
                id: groupId,
              });
              await this.getGroups();
              this.setSuccessNotification(`User "${userName}" successfuly deleted from "${groupName}" group`);
            } catch (error) {
              this.setErrorNotification(error.message);
            }
            this.loader = false;
          },
        });
      }
      this.setGeneralProgress(false);
    },
  },
};
