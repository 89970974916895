//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    data: { type: Array, default: () => ([]) },
  },

  data() {
    return {
      currencyId: null,
      input: '',
      state: [],
    };
  },

  computed: {
    ...mapState('currencies', ['currencies']),

    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    inputsConfig() {
      return {
        range: [
          { title: this.$t('Daily'), value: constant.transactions.LIMIT_PERIOD.DAY },
          { title: this.$t('Monthly'), value: constant.transactions.LIMIT_PERIOD.MONTH },
        ],
        types: constant.transactions.LIMIT_TYPE_LIST.map((e) => ({
          title: e.caption,
          value: `limit${e.id}`,
        })),
      };
    },

    isApplyPossibly() {
      const { currencyId } = this;
      return !!currencyId;
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.setState();
      }
    },
  },

  methods: {
    apply() {
      const { state, currencyId } = this;
      this.$emit('apply', { state, currencyId });
    },

    setState() {
      const { data, inputsConfig } = this;
      this.state = JSON.parse(JSON.stringify(data));
      inputsConfig.range.forEach((r) => {
        if (!this.state.some((e) => e.limitPeriod === r.value)) {
          this.state.push({ limitPeriod: r.value });
        }
        const current = this.state.find((e) => e.limitPeriod === r.value);
        inputsConfig.types.forEach((e) => {
          if (!Number.isFinite(current[e.value])) this.$set(current, e.value, null);
        });
      });
      if (data && data.length) {
        this.currencyId = data[0].currencyId;
      } else {
        const { DEFAULT_PRICE_EQUIVALENT_CURRENCY_TITLE } = constant.currencies;
        const { currencies } = this;
        const currency = currencies.find((e) => (
          e.title === DEFAULT_PRICE_EQUIVALENT_CURRENCY_TITLE
        ));
        if (currency) this.currencyId = currency.id;
      }
    },

    getValue(range, type) {
      const { state } = this;
      const value = state.find((e) => e.limitPeriod === range);
      if (value && Number.isFinite(value[type])) {
        return `${value[type]}`;
      }
      return '';
    },

    setValue(range, type, value) {
      const { state } = this;
      const item = state.find((e) => e.limitPeriod === range);
      if (item) {
        this.$set(item, type, value || null);
      } else {
        state.push({
          limitPeriod: range,
          [type]: value,
        });
      }
    },
  },
};
