//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

export default {
  props: {
    value: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },

  data() {
    return {
      currencyId: 0,
      perc: '',
      comment: '',
      fix: true,
    };
  },

  computed: {
    ...mapState('currencies', ['currencies']),
    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    stakingCurrencyList() {
      const { currencies } = this;
      return currencies.filter((e) => e.paraminingEn);
    },

    isApplyPossibly() {
      const { currencyId, perc, comment } = this;
      return currencyId && perc && comment;
    },
  },

  watch: {
    value() {
      this.currencyId = 0;
      this.perc = '';
      this.comment = '';
      this.fix = true;
    },
  },

  methods: {
    apply() {
      const {
        currencyId,
        perc,
        comment,
        fix,
      } = this;
      this.$emit('apply', {
        currencyId,
        perc,
        comment,
        fix,
      });
    },
  },
};
