var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"group-settings"},[_c('v-dialog',{attrs:{"value":_vm.value,"max-width":"480"},on:{"input":_vm.close}},[_c('v-card',{staticClass:"container container--fluid pt-0 pb-10"},[_c('v-card-title',{staticClass:"headline justify-center"},[_vm._v(" "+_vm._s(_vm.$t('Groups settings'))+" ")]),_c('v-card',{attrs:{"outlined":""}},[_c('v-simple-table',{attrs:{"fixed-header":"","height":"300px"}},[_c('thead',[_c('tr',{staticClass:"group-settings__table-header"},[_c('th',{attrs:{"width":"50%"}},[_vm._v(" "+_vm._s(_vm.$t('Group'))+" ")]),_c('th',{attrs:{"width":"30%"}},[_vm._v(" "+_vm._s(_vm.$t('Users'))+" ")]),_c('th',{attrs:{"width":"20%"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('DeleteShortCaption'))+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Delete group'))+" ")])])],1)])]),_c('tbody',[_vm._l((_vm.groups),function(group){return [_c('tr',{key:'group-settings-group-' + group.id,staticClass:"group-settings__table-row",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toggleExtra(group.id)}}},[_c('td',[_c('v-icon',{staticClass:"group-settings__chevron mr-2",class:{ 'group-settings__chevron--down': _vm.isExtra(group.id) },attrs:{"dense":""}},[_vm._v(" mdi-chevron-right ")]),_c('span',{staticClass:"group-settings__group-name"},[_vm._v(" "+_vm._s(group.name)+" ")])],1),_c('td',[_vm._v(" "+_vm._s(group.profiles.length)+" ")]),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteGroup(group)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Delete group'))+" ")])])],1)]),_c('tr',{key:'group-settings-extra-' + group.id,staticClass:"group-settings__extra"},[_c('td',{class:{'group-settings__extra--collapsed': !_vm.isExtra(group.id)},attrs:{"colspan":3}},[_c('v-expand-transition',[(_vm.isExtra(group.id))?_c('div',[_c('div',{staticClass:"d-flex align-center ma-1 mt-2 mb-3"},[_c('v-combobox',{staticClass:"mr-1",attrs:{"placeholder":"Add multiple user ids","type":"number","hide-spin-buttons":"","append-icon":"","clearable":"","multiple":"","no-filter":"","outlined":"","hide-details":"","dense":""},model:{value:(_vm.mapOfIdLists[group.id]),callback:function ($$v) {_vm.$set(_vm.mapOfIdLists, group.id, $$v)},expression:"mapOfIdLists[group.id]"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"outlined":"","color":"green","disabled":!(_vm.mapOfIdLists[group.id] && Object.values(_vm.mapOfIdLists[group.id]).length)},on:{"click":function($event){$event.stopPropagation();return _vm.addUsersToGroupHandler(group.id, group.name)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]}}],null,true)},[(!(_vm.mapOfIdLists[group.id] && Object.values(_vm.mapOfIdLists[group.id]).length))?_c('span',[_vm._v(" "+_vm._s(_vm.$t('addZeroUsersFromGroup'))+" ")]):(Object.values(_vm.mapOfIdLists[group.id]).length === 1)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('addOneUserFromGroup', { id: Object.values(_vm.mapOfIdLists[group.id]) }))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('addManyUserFromGroup', { idList: Object.values(_vm.mapOfIdLists[group.id]).join('\', \'') }))+" ")])])],1),_c('div',{staticClass:"d-flex align-center ma-1"},[_c('v-text-field',{staticClass:"mr-1",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"Search by id or name"},model:{value:(_vm.groupUserSearch[group.id]),callback:function ($$v) {_vm.$set(_vm.groupUserSearch, group.id, $$v)},expression:"groupUserSearch[group.id]"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-btn',{attrs:{"outlined":"","color":"error","disabled":!(_vm.selected[group.id] && Object.keys(_vm.selected[group.id]).length)},on:{"click":function($event){$event.stopPropagation();return _vm.deleteUsersFromGroup(group.id, group.name)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]}}],null,true)},[(!(_vm.selected[group.id] && Object.keys(_vm.selected[group.id]).length))?_c('span',[_vm._v(" "+_vm._s(_vm.$t('deleteZeroUsersFromGroup'))+" ")]):(Object.keys(_vm.selected[group.id]).length === 1)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('deleteOneUserFromGroup', { id: Object.keys(_vm.selected[group.id]) }))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('deleteManyUserFromGroup', { idList: Object.keys(_vm.selected[group.id]).join('\', \'') }))+" ")])])],1),_c('div',[_vm._l((group.profiles),function(profile){return [(profile.userId && profile.userName && _vm.showUserChip(group.id, profile.userId, profile.userName))?_c('v-tooltip',{key:'group-profile-' + profile.userId,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"grey--text text--lighten-2 ma-1 group-settings__chip",class:{
                                  'group-settings__chip--selected': _vm.selected[group.id] && _vm.selected[group.id][profile.userId],
                                },attrs:{"outlined":!(_vm.selected[group.id] && _vm.selected[group.id][profile.userId]),"close":""},on:{"click:close":function($event){return _vm.removeUserFromGroup({
                                  groupId: group.id,
                                  groupName: group.name,
                                  userId: profile.userId,
                                  userName: profile.userName
                                })},"click":function($event){return _vm.clickHandler(group.id, profile.userId)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(profile.userId)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(profile.userName)+" ")])]):_vm._e()]})],2)]):_vm._e()])],1)])]})],2)])],1),_c('div',{staticClass:"mt-6 group-settings__add-group"},[_c('h4',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.$t('Add new group'))+" ")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","outlined":"","clearable":"","placeholder":_vm.$t('Group name')},model:{value:(_vm.newGroup.name),callback:function ($$v) {_vm.$set(_vm.newGroup, "name", $$v)},expression:"newGroup.name"}})],1),_c('v-btn',{staticClass:"mt-4",attrs:{"outlined":"","color":"primary","width":"100%","loading":_vm.newGroup.loader,"disabled":!_vm.newGroup.name},on:{"click":_vm.addNewGroup}},[_vm._v(" "+_vm._s(_vm.$t('Add group'))+" ")])],1),_c('v-progress-linear',{attrs:{"active":_vm.loader,"rounded":false,"indeterminate":"","absolute":"","top":"","color":"primary"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }