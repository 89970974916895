//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
    showMerchantFees: { type: Boolean, default: false },
    showTraderFees: { type: Boolean, default: false },
    id: { type: Number, default: null },
    data: { type: Array, default: () => ([]) },
    currencies: { type: Array, default: () => ([]) },
  },

  data() {
    return {
      input: {
        currencyId: 1,
      },
      constant,
    };
  },

  computed: {
    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    sellFeePercent() {
      return `${this.data.find((e) => e.currencyId === this.input.currencyId)?.sellFeePercent ?? 0}%`;
    },
    buyFeePercent() {
      return `${this.data.find((e) => e.currencyId === this.input.currencyId)?.buyFeePercent ?? 0}%`;
    },
    sellProfitPercent() {
      return `${this.data.find((e) => e.currencyId === this.input.currencyId)?.sellProfitPercent ?? 0}%`;
    },
    buyProfitPercent() {
      return `${this.data.find((e) => e.currencyId === this.input.currencyId)?.buyProfitPercent ?? 0}%`;
    },
    isApplyPossible() {
      if (this.showMerchantFees) {
        return this.input.sellFeePercent !== '' && this.input.buyFeePercent !== '' && this.input.currencyId;
      }
      if (this.showTraderFees) {
        return this.input.sellProfitPercent !== '' && this.input.buyProfitPercent !== '' && this.input.currencyId;
      }
      return false;
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.input = {};
      }
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
    redirectToUserPage() {
      this.$router.push(`/user/${this.$props.id}`);
    },
  },
};
