//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      editingTagName: '',
      editingTagNameId: 0,
      newTag: {
        tag: '',
        color: constant.users.DEFAULT_USER_TAG_COLOR,
        loader: false,
      },
      loader: false,
    };
  },

  computed: {
    ...mapState('users', ['userTagsList']),
  },

  methods: {
    ...mapActions('common', [
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),

    ...mapActions('users', ['getUserTagsList', 'addUserTag', 'deleteUserTag', 'editUserTag']),

    close() {
      this.$emit('input', false);
    },

    getTagColor(tag) {
      const { DEFAULT_USER_TAG_COLOR } = constant.users;

      if (!tag.color) {
        return DEFAULT_USER_TAG_COLOR;
      }
      return tag.color;
    },

    async openEditingTagName(tagId) {
      this.editingTagNameId = tagId;
      const { userTagsList } = this;
      const { tagName } = userTagsList.find((e) => e.id === tagId);
      this.editingTagName = tagName;
      await this.$nextTick();
      this.$refs[`editTag_${tagId}`][0].focus();
    },

    closeEditingTagName() {
      this.editingTagNameId = 0;
    },

    async editTagName(tagId) {
      this.loader = true;
      try {
        await this.editUserTag({
          tagId,
          tag: this.editingTagName,
        });
        await this.getUserTagsList();
        this.setSuccessNotification('Tag successfuly updated');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.editingTagNameId = 0;
      this.loader = false;
    },

    async editTagColor({ hex }, tagId) {
      this.loader = true;
      try {
        await this.editUserTag({
          tagId,
          color: hex,
        });
        await this.getUserTagsList();
        this.setSuccessNotification('Tag successfuly updated');
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.loader = false;
    },

    async removeTag(tagId) {
      this.confirmAction({
        title: 'Are you sure you want to delete tag?',
        callback: async () => {
          this.loader = true;
          try {
            await this.deleteUserTag(tagId);
            await this.getUserTagsList();
            this.setSuccessNotification('Tag successfuly deleted');
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.loader = false;
        },
      });
    },

    async editNewTagColor({ hex }) {
      this.newTag.color = hex;
    },

    async addNewTag() {
      const { DEFAULT_USER_TAG_COLOR } = constant.users;

      this.newTag.loader = true;

      try {
        const { tag, color } = this.newTag;
        await this.addUserTag({ tag, color });
        await this.getUserTagsList();
        this.setSuccessNotification('Tag successfuly created');
      } catch (error) {
        this.setErrorNotification(error.message);
      }

      this.newTag.loader = false;
      this.newTag.tag = '';
      this.newTag.color = DEFAULT_USER_TAG_COLOR;
    },
  },
};
