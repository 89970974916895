//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import i18n from '~/plugins/i18n';

export default {
  props: {
    items: { type: Array, default: () => [] },
    value: { type: Object, default: () => [] },
    itemText: { type: Function, default: (e) => i18n.t(`const.${e.caption}`) },
    itemValue: { type: Function, default: (e) => e.id },
  },

  methods: {
    setSort(e) {
      const { value } = this;
      this.$emit('input', {
        ...value,
        sort: e,
      });
    },

    setSortDesc() {
      const { value } = this;
      this.$emit('input', {
        ...value,
        sortDesc: !value.sortDesc,
      });
    },
  },
};
