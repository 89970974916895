//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';
import Tag from '~/components/tags/Tag.vue';
import TagsSettings from '~/components/tags/Settings.vue';

export default {

  components: { Tag, TagsSettings },
  props: {
    list: { type: Array, default: () => [] },
  },

  data() {
    return {
      showSettings: false,
    };
  },

  computed: {
    ...mapState('users', ['userTagsList', 'userTagListFilter']),
  },

  methods: {
    ...mapActions('users', ['setUserTagListFilter']),

    applyTag(id) {
      const { userTagListFilter } = this;
      const includes = userTagListFilter.includes(id);

      if (includes) {
        const filtered = userTagListFilter.filter((e) => e !== id);
        this.setUserTagListFilter(filtered);
      } else {
        const extended = [
          ...userTagListFilter,
          id,
        ];
        this.setUserTagListFilter(extended);
      }
      this.$emit('update');
    },
  },
};
