import constant from '~/const';

const { USER_LOCK_TYPE_LIST } = constant.users;

const blockList = USER_LOCK_TYPE_LIST.map((e) => ({
  caption: e.caption,
  action: 'block',
  payload: e.id,
}));

export default [
  {
    caption: 'Set fees and limits',
    action: 'setFeesAndLimits',
  },
  {
    caption: 'Customize tags',
    action: 'customizeTags',
  },
  {
    caption: 'Set staking',
    action: 'setStaking',
  },
  {
    caption: 'Block',
    action: null,
    children: blockList,
  },
];
