//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import Tag from '~/components/tags/Tag.vue';

export default {

  components: { Tag },
  props: {
    value: { type: Boolean, default: false },
  },

  data() {
    return {
      selection: [],
    };
  },

  computed: {
    ...mapState('users', ['userTagsList']),

    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.selection = [];
      }
    },
  },

  methods: {
    add() {
      const { selection } = this;
      this.$emit('add', selection);
    },
    remove() {
      const { selection } = this;
      this.$emit('remove', selection);
    },
  },
};
