import $utils from '../../utils/storeUtils';

export default [
  {
    width: 20,
    header: {
      type: 'slot',
      name: 'customActions',
    },
    cell: {
      type: 'slot',
      name: 'username',
      class: 'text-no-wrap',
    },
  },
  {
    width: 8,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 18,
    header: {
      type: 'text',
      caption: 'Contact Info',
    },
    cell: {
      type: 'text',
      value: (e) => $utils.isIdentificatorType(e.identificatorList, [0, 1, 3]),
    },
  },
  {
    width: 5,
    header: {
      type: 'slot',
      name: 'mfaHeader',
    },
    cell: {
      type: 'slot',
      name: 'mfa',
    },
  },
  {
    width: 10,
    header: {
      type: 'slot',
      name: 'verHeader',
    },
    cell: {
      type: 'slot',
      name: 'ver',
    },
  },
  // {
  //   width: 10,
  //   header: {
  //     type: 'text',
  //     caption: 'Location',
  //   },
  //   cell: {
  //     type: 'text',
  //     value: (e) => e.verificationStatus,
  //   },
  // },
  {
    width: 39,
    header: {
      type: 'slot',
      name: 'tagsHeader',
    },
    cell: {
      type: 'slot',
      name: 'tags',
    },
  },
];
