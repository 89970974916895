//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Groups from '~/components/groups/Groups.vue';

export default {
  components: { Groups },

  data() {
    return {
      showSettings: false,
    };
  },
};
